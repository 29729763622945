<template>
    <DynamicScroller
            :items="chronology"
            :min-item-size="88"
    >
        <template #before>
            <!-- start -->
        </template>

        <template v-slot="{ item, active }">
            <DynamicScrollerItem
                    :item="item"
                    :active="active"
                    :size-dependencies="[ item.type, violations ]"
            >

                <TracksList_track_chronology_item
                    :item="item"
                    :violations="violations.filter(i => {
                        return item.time_start.utc <= i.time.utc && i.time.utc <= item.time_end.utc
                    })"
                />

            </DynamicScrollerItem>
        </template>

        <template #after>
            <!--  end -->
        </template>

    </DynamicScroller>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import TracksList_track_chronology_item from "./TracksList_track_chronology_item.lite.vue";

    export default {
        name: "TracksList_track_chronology.lite",
        props: [
            'unitId',
            'date',
        ],
        components: {
            TracksList_track_chronology_item
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "getShowUnitTrack",
                "getTripsFiltered",
                "getParkingsFiltered",

                "getTripsIndexFiltered",
                "getParkingsIndexFiltered",

                "getStopsFiltered",
                "getEventsFiltered",
            ]),
            chronology(){
                let items = []
                if(this.getTripsFiltered.length){
                    items.push(...this.getTripsFiltered)
                }
                if(this.getParkingsFiltered.length){
                    items.push(...this.getParkingsFiltered)
                }
                items.sort(function (a, b) {
                    let cmp = a.time_start.utc - b.time_start.utc
                    return cmp > 0 ? 1 : (cmp < 0 ? -1 : 0)
                })
                return items
            },

            violations(){
                let items = []
                if(this.getStopsFiltered.length){
                    let events = this.getStopsFiltered.map(e => {
                        let message = e.text || ''
                        if(e.tmp_msg && e.tmp_msg.msg){
                            message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                        }
                        return {...e, message}
                    })
                    items.push(...events)
                }
                if(this.getEventsFiltered.length){
                    let events = this.getEventsFiltered.map(e => {
                        let message = e.text || ''
                        if(e.tmp_msg && e.tmp_msg.msg){
                            message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                        }
                        return {...e, message}
                    })
                    items.push(...events)
                }
                items.sort(function (a, b) {
                    let cmp = a.time.utc - b.time.utc
                    return cmp > 0 ? 1 : (cmp < 0 ? -1 : 0)
                })
                return items
            },
        },
        methods: {
            ...mapActions([
                "setShowUnitTrack",
            ]),
        },
        created() {
        },
        updated() {
            //console.log('TracksList_track_chronology.lite updated', this.unitId, this.date)
        },
        activated() {
        },
        deactivated() {
        },
        mounted(){
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>