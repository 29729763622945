<template>
    <div class="navigation__button-group">
        <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more_vertical"/></span> </span>
        </button>
        <div class="navigation__dropdown-menu dropdown-menu position_right-bottom">
            <div class="dropdown-menu__wrap">
                <ul class="dropdown-menu__list">
                    <li class="dropdown-menu__item item" @click="onClick('pdf')">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__export"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Export history to PDF') }}</span>
                    </li>
                    <li class="dropdown-menu__item item" @click="onClick('img')" v-if="false">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__export"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Export history to IMG') }}</span>
                    </li>
                    <li class="dropdown-menu__item item" @click="onClick('csv')">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__export"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Export history to CSV') }}</span>
                    </li>
                    <li class="dropdown-menu__item item" @click="onClick('json')" v-if="false">
                        <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__export"/></span> </span>
                        <span class="dropdown-menu__item-name">{{ $t('menu.Export history to JSON') }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <a style="display: none;" :href="url" target="_blank" ref="download" />
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'TracksList_navigation_dropdown_menu',
        components: {
        },
        data() {
            return {
                url: '',
            }
        },
        computed: {
            ...mapGetters([
                "getShowUnitTrack",
            ]),
        },
        methods: {
            onClick(format) {
                let props = {...this.getShowUnitTrack, lang: this.$root.$i18n.locale};
                this.$api.exports.build('track', format, props)
                    .then((response) => {
                        // console.log(response);
                        if(response.data && !response.data.error){
                            this.url = this.$config.exports.baseURL+response.data.file
                            // console.log('response.data', response.data, this.url)
                            this.$nextTick(() => {
                                this.$refs.download.click()
                            });
                        }else{
                            let message = (response.data || {}).message || 'error!'
                            this.$toast.error(this.$t(message))
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        created() {
            // console.log('TracksList_navigation_dropdown_menu created')
        },
        mounted() {
            // console.log('TracksList_navigation_dropdown_menu mounted')
        },
        updated() {
            // console.log('TracksList_navigation_dropdown_menu updated')
        },
        beforeDestroy() {
        },
    }
</script>
