<template>
    <section class="track-chronology plr__16 pt__16 mb__12"
             :class="{'track-chronology_status_active': isActive}"
             @click="showUnitTrack"
    >
        <ul class="track-chronology__list list list_top">
            <li class="track-chronology__item">
                <div class="parameter mb__12">
                    <span class="parameter__icon icon icon_type_circle "
                          :class="{icon_theme_primary_fill: item.type == 'parking',icon_theme_secondary_fill: item.type == 'driving'}"
                    >
                      <span class="icon__symbol">
                          <SvgIcon class="icon" :name="icon"/>
                      </span>
                      <span class="icon__events">{{violations.length}}</span>
                    </span>
                    <h5 class="parameter__label label label_title">
                        <span class="parameter__label-title">{{item.time_start.formated["time-s"]}}</span>
                        <span class="parameter__label-title">{{item.duration.formated}}</span><!-- 1h 20m -->
                        <span class="parameter__label-title">{{item.time_end.formated["time-s"]}}</span>
                    </h5>
                    <p class="parameter__value">
                        {{item.address_start ? item.address_start: item.coord_start}}
                        <!-- <br>{{item.address_end ? item.address_end: item.coord_end}} -->
                    </p>
                </div>
            </li>
            <li class="track-chronology__item"
                v-if="item.type == 'driving'"
            >
                <div class="parameter mb__16">
                    <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__max-speed"/></span> </span>
                    <ul class="parameter__list">
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                            <p class="parameter__value">
                                {{item.mileage}} {{distanceUnits}}
                            </p>
                        </li>
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Maximum speed') }}</h5>
                            <p class="parameter__value">
                                {{item.speed_max}} {{speedUnits}}
                            </p>
                        </li>
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Average speed') }}</h5>
                            <p class="parameter__value">
                                {{item.speed_avg}} {{speedUnits}}
                            </p>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <div class="horizontal-line mb__16 "
             :class="{'status_hidden': item.type == 'parking'}"
        >
            <button class="button button_view_base button_width_100p button_size_sm mr__8"
                    :class="{
                        'button_theme_neutral': !isShowUnitTrackColor,
                        'button_theme_primary': isShowUnitTrackColor
                    }"
                    @click.stop="speedColor"
            >
                {{ $t('buttons.Speed') }}
            </button>
            <button class="button button_view_base button_width_100p button_size_sm mr__8"
                    :class="{
                        'button_theme_neutral': !detailed,
                        'button_theme_primary': detailed
                    }"
                    @click.stop="[detailed = !detailed , showUnitTrack() ]"
            >
                {{ $t('buttons.Details') }}
            </button>
            <button class="button button_view_base button_width_100p button_size_sm mr__8"
                    :class="{
                        'button_theme_neutral': !isUnitMoveOnTrack,
                        'button_theme_primary': isUnitMoveOnTrack
                    }"
                    @click.stop="moveOnTrack"
            >
                {{ $t('buttons.Play') }}
            </button>
        </div>
        <div class="horizontal-line mb__16 "
             :class="{'status_hidden': item.type == 'parking'}"
             v-show="isUnitMoveOnTrack"
        >
            <button class="button button_view_base button_width_100p button_size_sm mr__8"
                    v-for="(button, speed) in speedButtons"
                    :key="''+item.id+'-speed-'+speed"
                    :class="{
                        'button_theme_neutral': getUnitMoveOnTrackSpeed-speed,
                        'button_theme_primary': !(getUnitMoveOnTrackSpeed-speed),
                    }"
                    @click.stop="setUnitMoveOnTrackSpeed(speed)"
            >
                {{ button }}
            </button>
        </div>
        <ul class="track-chronology__list "
            :class="{'status_hidden': !detailed || (item.type == 'parking' && !item.violations.length)}"
        >
            <li class="track-chronology__item"
                v-if="item.type == 'driving'"
            >
                <div class="parameter mb__12">
                    <span class="parameter__icon icon icon_type_circle icon_theme_secondary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__a-point"/></span> </span>
                    <h5 class="parameter__label">{{item.time_start.formated["time-s"]}} / {{ $t('text.Start') }}</h5>
                    <p class="parameter__value">
                        {{item.address_start ? item.address_start: item.coord_start}}
                    </p>
                </div>
            </li>
            <li class="track-chronology__item"
                v-if="false"
            >
                <div class="parameter mb__12">
                    <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                    <ul class="parameter__list">
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Mileage') }}</h5>
                            <p class="parameter__value">
                                {{item.mileage}} {{distanceUnits}}
                            </p>
                        </li>
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Duration') }}</h5>
                            <p class="parameter__value">
                                {{item.duration.formated}}
                            </p>
                        </li>
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Max speed') }}</h5>
                            <p class="parameter__value">
                                {{item.speed_max}} {{speedUnits}}
                            </p>
                        </li>
                        <li class="parameter__item">
                            <h5 class="parameter__label">{{ $t('text.Aver speed') }}</h5>
                            <p class="parameter__value">
                                {{item.speed_avg}} {{speedUnits}}
                            </p>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="track-chronology__item"
                v-for="(violation, i) in violations"
                :key="''+item.id+'-violation-'+i"
                :class="{
                    'track-chronology__item-status_active': getShowMarkerOnTrack &&
                    getShowMarkerOnTrack.type == violation.type + (violation['sub-type']? '.'+violation['sub-type']: '') &&
                    getShowMarkerOnTrack.time == violation.time.utc
                }"
                @click.stop="showMarkerOnTrack(violation)"
            >
                <div class="parameter mb__12">
                    <span class="parameter__icon icon icon_type_circle"
                          :class="{icon_theme_danger: violation.type == 'event', icon_theme_primary: violation.type == 'stop'}"
                    >
                        <span class="icon__symbol">
                            <SvgIcon class="icon" :name="violation_icon(violation)"/>
                        </span>
                    </span>
                    <h5 class="parameter__label">
                        {{violation.time.formated["time-s"]}} /
                        {{violation.message || violation.text}}
                    </h5>
                    <p class="parameter__value">
                        {{violation.address ? violation.address : violation.latlng}}
                    </p>
                </div>
            </li>
            <li class="track-chronology__item"
                v-if="item.type == 'driving'"
            >
                <div class="parameter mb__12">
                    <span class="parameter__icon icon icon_type_circle icon_theme_primary_fill"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__b-point"/></span> </span>
                    <h5 class="parameter__label">{{item.time_end.formated["time-s"]}} / {{ $t('text.Finish') }}</h5>
                    <p class="parameter__value">
                        {{item.address_end ? item.address_end: item.coord_end}}
                    </p>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'TracksList_track_chronology_item.lite',
    props: [
        'item',
        'violations'
    ],
    components: {
    },
    data() {
        return {
            speedButtons: {1:'1x',2:'2x',4:'4x',8:'8x',16:'16x'},
            detailed: false,
            speedColorFlag: true,
        }
    },
    computed: {
        ...mapGetters([
            "getAppUser",
            "getAppUserUnits",
            "getShowUnitTrackIndex",
            "getShowUnitTrackColor",
            "getUnitMoveOnTrack",
            "getUnitMoveOnTrackSpeed",
            "getShowMarkerOnTrack",
        ]),
        speedUnits(){
            return this.getAppUserUnits.speed
        },
        distanceUnits(){
            return this.getAppUserUnits.distance
        },

        isUnitMoveOnTrack(){
            return (
                this.isActive &&
                this.getUnitMoveOnTrack &&
                this.getUnitMoveOnTrack.id == this.item.id
            )
        },
        isShowUnitTrackColor() {
            return (
                this.isActive &&
                this.getShowUnitTrackIndex && 
                this.getShowUnitTrackIndex.id == this.item.id && 
                this.getShowUnitTrackIndex.trackColor == true
            )
        },
        activeViolations(){
            //if(!this.getShowMarkerOnTrack) return null
            let filter = this.getShowMarkerOnTrack || {}
            return this.violations.filter(v =>
                filter.type == v.type + (v['sub-type'] ? '.' + v['sub-type'] : '') &&
                filter.time == v.time.utc
            )
        },
        isActive(){
            return this.getShowUnitTrackIndex
                && this.item.time_start.utc == this.getShowUnitTrackIndex.time_start
                && this.item.time_end.utc   == this.getShowUnitTrackIndex.time_end
        },
        icon(){
            return 'circle__' + (this.item.type=='driving'? 'd-point' : 'parking')
        }
    },
    watch:{
        getShowMarkerOnTrack(newVl/*, oldVl*/){
            //console.log('getShowMarkerOnTrack', newVl, oldVl)
            if(newVl && this.isActive) this.detailed = true
            if(this.activeViolations.length) {
                setTimeout(() => this.scrollToViolation(), 300)
            }
        },
        // activeViolations(newVl){
        //     console.log('activeViolations', newVl)
        // },
        detailed(newVl){
            if(!newVl){
                this.setShowMarkerOnTrack(null)
            }
        },
        isActive(newVl){
            if(!newVl){
                this.detailed = false
                //this.setShowMarkerOnTrack(null)
                if(this.getUnitMoveOnTrack) {
                    this.setUnitMoveOnTrack(null)
                }
            }
        },
    },
    methods: {
        ...mapActions([
            "setShowUnitTrackIndex",
            "setUnitMoveOnTrack",
            "setUnitMoveOnTrackSpeed",
            "setUnitTrackColor",
            "setShowMarkerOnTrack",
            "setMapCenter",
            "setMapBounds",
        ]),
        speedColor() {
            if(!this.isActive) {
               this.showUnitTrack()
            } else {
                this.setUnitTrackColor(!this.getShowUnitTrackColor)
            }
        },
        scrollToViolation(){
            let elem = document.querySelector('.track-chronology__item-status_active');
            if(elem) {
                let parentElem = elem.closest('.track-chronology_status_active');
                if(parentElem) {
                    setTimeout(() =>  parentElem.scrollIntoView({block: "center", behavior: "smooth", inline: "center"}), 300);
                }
                setTimeout(() => elem.scrollIntoView({ block: "center", behavior: "smooth" ,inline:"center"}) , 400)
            }
        },
        showMarkerOnTrack(violation){
            // this.setMapCenter(violation.latlng)
            this.setMapBounds([violation.latlng])
            this.setShowMarkerOnTrack({
                type: violation.type + (violation['sub-type']? '.'+violation['sub-type']: ''),
                time: violation.time.utc,
                //latlng: violation.latlng
            })
        },
        violation_icon(violation){
            let icon = 'circle__'
            if(violation.type == 'stop') {
                icon += 'stop'
            }else
            if(violation.type == 'event') {
                icon += violation['sub-type'].replaceAll(' ', '-')
            }
            return icon
        },
        showUnitTrack(){
            if(this.isActive) {
                //ToDo calc track bound and set
                //this.setMapBounds([])
                this.setShowMarkerOnTrack(null)
                this.setShowUnitTrackIndex({
                    ...this.getShowUnitTrackIndex,
                    rebuild: Date.now()
                })
                return
            }
            if(!this.isActive && this.getUnitMoveOnTrack) {
                this.setUnitMoveOnTrack(null)
            }
            this.setShowUnitTrackIndex({
                id: this.item.id,
                type: this.item.type,
                time_start: this.item.time_start.utc,
                time_end: this.item.time_end.utc,
                index: this.index,
                trackColor: true
            })
        },
        async moveOnTrack() {
            if (this.isUnitMoveOnTrack) {
                this.setUnitMoveOnTrack(null)
                return
            }
            let tm = 1;
            if (!this.isActive) {
                await this.showUnitTrack()
                tm = 300
            }
            this.setUnitMoveOnTrackSpeed(1)
            setTimeout(() =>
                this.setUnitMoveOnTrack({
                    id: this.item.id,
                    time_start: this.item.time_start.utc,
                    time_end: this.item.time_end.utc,
                    index: this.index,
                }), tm
            )
        }
    },
    created() {
    },
    updated() {
        //console.log('TracksList_track_chronology_item.lite updated')
    },
    activated() {
    },
    deactivated() {
    },
    mounted(){
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>
.track-chronology__item-status_active {
    position: relative;
    background: var(--color-primary-50);
}
.track-chronology__item-status_active:before {
    content: '';
    position: absolute;
    top: 0;
    left: -3px;
    display: block;
    width: 3px;
    height: 100%;
    background: var(--color-primary-400);
    border-radius: 0.0625rem;
}
</style>