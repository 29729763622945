<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
        <div class="horizontal-line mb__12">
            <SegmentControl_slider
                :segment-control-class="'mr__8'"
                :field_id="'date'"
                :field_name="'label'"
                :items="dates"
                v-model="track_date"
            />
            <TracksList_navigation_dropdown_menu/>
        </div>

        <div class="horizontal-line section__navigation-mobile">
            <FieldGroup_kwrapper_dropdownlist
                :field-group_class="'field-group_view_compact mr__12'"
                :id="'track-unit'"
                :label="$t('Track.Unit')"
                :info="''"
                :items="unitsActivated"
                :error="errors.unitId"
                v-model="track_unitId"
            />
            <FieldGroup_flatpickr
                :field-group_class="'field-group_view_calendar field-group_view_compact'"
                :id="'track-date'"
                :label="$t('Track.Day')"
                :input_icon="'input__date'"
                :max-date="getDateToday"
                :error="errors.date"
                v-model="track_date"
            />
        </div>

    </nav>
</template>

<script>
    import {mapActions, mapMutations, mapGetters} from "vuex";
    import TracksList_navigation_dropdown_menu from "./TracksList_navigation_dropdown_menu.vue";
    import {dateFormat} from "@/filters/dateFormat";

    export default {
        name: "TracksList_navigation",
        components: {
            TracksList_navigation_dropdown_menu
        },
        data() {
            return {
                pageActive: false,
                interval: null,
                errors: {
                    // unitId: 'bad unit',
                    // date: 'bad date'
                },
            }
        },
        computed: {
            ...mapGetters([
                "units",
                "unitsActivated",
                "getShowUnitTrack",
                "getTripsFilter",
                "getParkingsFilter",
                "getEventsFilter",
                "getStopsFilter",
                "getTracksFilter",
                "getDateToday",
                "getAppUserList30Days",
                "getTimeToday",
                "getDateFormatAll",
            ]),
            dates(){
                let dates = this.getAppUserList30Days.map(dt => {
                    return {...dt, label: dateFormat(dt.date,this.getDateFormatAll.short || 'MMM D' , this.$root.$i18n.locale)}
                })
                dates[0].label = this.$t('text.Today')
                dates[1].label = this.$t('text.Yest_')
                return dates
            },
            track_unitId: {
                get () {
                    return this.getShowUnitTrack.unitId || 0
                },
                set (unitId) {
                    this.setUnitMoveOnTrack(null)
                    this.setShowUnitTrackIndex(null)
                    this.setShowMarkerOnTrack(null)

                    this.setShowUnitTrack({...this.getShowUnitTrack, unitId})

                    this.setTripsFilter({...this.getTripsFilter, unitId})
                    this.setParkingsFilter({...this.getParkingsFilter, unitId})
                    this.setEventsFilter({...this.getEventsFilter, unitId})
                    this.setStopsFilter({...this.getStopsFilter, unitId})
                    this.setTracksFilter({...this.getTracksFilter, unitId})
                }
            },
            track_date: {
                get () {
                    return this.getShowUnitTrack.date || ''
                },
                set (date) {
                    this.setUnitMoveOnTrack(null)
                    this.setShowUnitTrackIndex(null)
                    this.setShowMarkerOnTrack(null)

                    this.setShowUnitTrack({...this.getShowUnitTrack, date})

                    this.setTripsFilter({...this.getTripsFilter, date})
                    this.setParkingsFilter({...this.getParkingsFilter, date})
                    this.setEventsFilter({...this.getEventsFilter, date})
                    this.setStopsFilter({...this.getStopsFilter, date})
                    this.setTracksFilter({...this.getTracksFilter, date})
                }
            },
        },
        methods: {
            ...mapActions([
                "setShowMarkerOnTrack",
                "setUnitMoveOnTrack",
                "setShowUnitTrackIndex",
                "setShowUnitTrack",
            ]),
            ...mapMutations([
                "setTripsFilter",
                "setParkingsFilter",
                "setEventsFilter",
                "setStopsFilter",
                "setTracksFilter",
                "calcDateToday",
            ]),
        },
        created() {
            // console.log('TracksList_navigation created')
        },
        updated() {
            // console.log('TracksList_navigation updated')
        },
        activated() {
            // console.log('TracksList_navigation activated')
            this.calcDateToday()
        },
        deactivated() {
            // console.log('TracksList_navigation deactivated')
        },
        mounted(){
            // console.log('TracksList_navigation mounted')
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>