<template>
    <section class="section__body">

        <!--- Navigation --->
        <TracksList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">

            <SectionLoading :inprogress="inprogress" />

            <section class="section section_type_loader loader "
                     :class="{'loader_status_active': no_data }"
            >
                <span class="loader__text">{{ $t('text.No data') }}</span>
            </section>

            <TracksList_track_chronology :unit-id="track_unitId" :date="track_date" />

        </section>
    </section>
</template>

<script>
    import debounce from "lodash/debounce"
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import TracksList_navigation from "./TracksList_navigation.lite.vue";
    import TracksList_track_chronology from "./TracksList_track_chronology.lite.vue";

    export default {
        name: "TracksList",
        components: {
            TracksList_navigation,
            TracksList_track_chronology,
        },
        data() {
            return {
                pageActive: false,
                debouncedFetchData: null,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "units",
                "getShowUnitTrack",
                "getTripsFiltered",
                "getTripsIndexFiltered",
                "getParkingsFiltered",
                "getParkingsIndexFiltered",
                "getTracksIndexFiltered",
                "getDateToday"
            ]),
            inprogress(){
                return (this.track_unitId>0 && this.track_date>'')
                    && !this.getTripsIndexFiltered
                    && !this.getParkingsIndexFiltered
            },
            no_data() {
                return (this.track_unitId>0 && this.track_date>'')
                    && !this.inprogress
                    && !this.getTripsFiltered.length
                    && !this.getParkingsFiltered.length
            },
            track_unitId(){
                return this.getShowUnitTrack.unitId || 0
            },
            track_date(){
                return this.getShowUnitTrack.date || ''
            },
        },
        watch: {
            getShowUnitTrack: {
                handler: function (/*newVl, oldVl*/) {
                    //if(newVl.unitId !== oldVl.unitId || newVl.date !== oldVl.date)
                    {
                        // console.log('getShowUnitTrack', newVl, oldVl)
                        this.debouncedFetchData();
                    }
                },
                deep: true
            },
            getTripsIndexFiltered: {
                handler: function (newVl/*, oldVl*/) {
                    if(newVl){
                        // console.log('getTripsIndexFiltered', newVl, oldVl)
                        this.getUnitTracks();
                    }
                },
                deep: true
            }
        },
        methods: {
            ...mapActions([
                "setShowUnitTrack",
                "setShowUnitTrackIndex",
                "fetchTrips4Date",
                "fetchParkings4Date",
                "fetchEvents4Date",
                "fetchStops4Date",
                "fetchTracks4Period",
            ]),
            ...mapMutations([
                "setEventsFilter",
                "setStopsFilter",
            ]),
            fetchData(){
                //console.log('fetchData', this.getShowUnitTrack, this.getTripsIndexFiltered, this.getParkingsIndexFiltered)
                if(this.getTripsIndexFiltered || this.getParkingsIndexFiltered)//(this.track_unitId>0 && this.track_date>'')
                    return false

                this.fetchStops4Date(this.getShowUnitTrack)
                this.fetchEvents4Date(this.getShowUnitTrack)
                this.fetchParkings4Date(this.getShowUnitTrack)
                this.fetchTrips4Date(this.getShowUnitTrack)
            },
            getUnitTracks(){
                //console.log('getUnitTracks', this.getTripsFiltered)
                let params = this.getShowUnitTrack
                this.getTripsFiltered.forEach(t => {
                    params.from = t.time_start.utc
                    params.to = t.time_end.utc
                    this.fetchTracks4Period(params)
                })
            }
        },
        created() {
            this.debouncedFetchData = debounce(this.fetchData, 500)
            this.setShowUnitTrack({
                unitId: null,
                date: this.getDateToday,
            })
        },
        updated() {
            //console.log('TracksList updated')
        },
        activated() {
            this.pageActive = true
            let filter = this.getShowUnitTrack//{ unitId: '', date: '' }
            this.setEventsFilter(filter)
            this.setStopsFilter(filter)

            this.interval = setInterval(() => {
                //
            }, 60*1000)
        },
        deactivated() {
            this.pageActive = false
            this.setShowUnitTrackIndex({})
            if(this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
            // this.setShowUnitTrack({
            //     unitId: null,
            //     date: new Date().toISOString().split('T')[0],
            // })
        },
        mounted(){
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>